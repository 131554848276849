<div class="backgroundimageclass">
  <div class="container row">
    <mat-card class="col-sm-6 login-page">
      <mat-card-title>Login</mat-card-title>
      <mat-card-content>
        <form [formGroup]="form" class="login-form">
          <mat-form-field appearance="outline">
            <mat-label>Email</mat-label>
            <input matInput type="email" formControlName="email" />
            <mat-error
              *ngIf="
                form.get('email').hasError('email') && form.get('email').touched
              "
              class="errormsg"
              >Invalid email</mat-error
            >
          </mat-form-field>

          <mat-form-field appearance="outline">
            <mat-label>Password</mat-label>
            <input matInput type="password" formControlName="password" />
          </mat-form-field>
          <button
            mat-raised-button
            color="primary"
            (click)="login()"
            [disabled]="!form.valid"
          >
            Login
          </button>
        </form>
        <span class="errormsg" *ngIf="errorOnLogin$ | async"
          >Invalid User credentials</span
        >
        <div class="contact">
          <a [href]="'tel:' + phoneNumber">For more details </a>
          <span style="color: rgb(61, 61, 61)">:</span>
          <span style="color: rgb(61, 61, 61)"
            ><span> 9019775970</span> /
            <span style="color: rgb(61, 61, 61)"> 9980094927</span></span
          >
        </div>
        <div></div>
      </mat-card-content>
    </mat-card>
  </div>
</div>
